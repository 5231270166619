import React from "react";

const SkillIcon = ({ src, style, name }) => {
  return (
    <div className="skills tooltip">
      <img src={src} alt="" />
      <span className="tooltiptext" style={style}>
        {name}
      </span>
    </div>
  );
};

export default SkillIcon;
