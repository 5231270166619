import React, { forwardRef } from "react";
import { Section } from "./Section";
import SkillIcon from "./SkillIcon";
import { skills } from "./SkillsData";

const Skills = forwardRef((props, ref) => {
  return (
    <div className="parentSkills" ref={ref} id="skills">
      <Section main={`Skills`} sub={`My technical skills`} />
      <div className="container skillsContainer">
        {skills.map((item) => (
          <SkillIcon
            name={item.name}
            src={item.src}
            style={item.style}
            key={item.name}
          />
        ))}
      </div>
    </div>
  );
});

export default Skills;
