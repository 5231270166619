import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Qualifications from "./components/Qualifications";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import Lower from "./components/Lower";
import { useInView } from "react-intersection-observer";

import { useRef, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [showSideBar, setShowSideBar] = useState(false);
  const showSideBarHandler = (value) => {
    setShowSideBar(value);
  };

  const [homeRef, homeInView] = useInView({
    threshold: 0.5,
  });

  const [aboutRef, aboutInView] = useInView({
    threshold: 0.5,
  });
  const [skillRef, skillInView] = useInView({
    threshold: 0.5,
  });
  const [qualRef, qualInView] = useInView({
    threshold: 0.5,
  });
  const [projectsRef, projectsInView] = useInView({
    threshold: 0.5,
  });
  const [contactRef, contactInView] = useInView({
    threshold: 0.5,
  });

  const activeTab = () => {
    if (homeInView) {
      return 0;
    } else if (aboutInView) {
      return 1;
    } else if (qualInView) {
      return 2;
    } else if (projectsInView) {
      return 3;
    } else if (skillInView) {
      return 4;
    } else if (contactInView) {
      return 5;
    } else {
      return -1;
    }
  };

  return (
    <div className="body__div">
      <ScrollToTop />
      {/* Navbar */}
      <Navbar activeTab={activeTab} showSideBarHandler={showSideBarHandler} />
      {showSideBar && (
        <SideBar
          activeTab={activeTab}
          showSideBarHandler={showSideBarHandler}
        />
      )}
      <div className="app_body">
        {/* Home */}
        <Home ref={homeRef} />
        {/* About */}
        <About ref={aboutRef} />
        {/* Qualifications */}
        <Qualifications ref={qualRef} />
        {/* Projects */}
        <Projects ref={projectsRef} />
        {/* Skills */}
        <Skills ref={skillRef} />
        {/* Contact Me */}
        <Contact ref={contactRef} />
        {/* Footer */}
        <Footer />
        {/* Lower Footer*/}
        <Lower />
      </div>
    </div>
  );
}

export default App;
