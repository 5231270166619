import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { makeStyles } from "@mui/styles";

// const Form = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_rvwc2we",
//         "template_6cino2m",
//         form.current,
//         process.env.EMAIL_API_KEY
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };
//   return (
//     <div className="actualForm">
//       <form ref={form} onSubmit={sendEmail}>
//         <div
//           className="wrap-input2 validate-input"
//           data-validate="Name is required"
//         >
//           <input className="input2" type="text" name="user_name" />
//           <span className="focus-input2" data-placeholder="NAME"></span>
//         </div>
//         <div
//           className="wrap-input2 validate-input"
//           data-validate="Valid email is required: ex@abc.xyz"
//         >
//           <input className="input2" type="text" name="user_email" />
//           <span className="focus-input2" data-placeholder="EMAIL"></span>
//         </div>
//         <div
//           className="wrap-input2 validate-input"
//           data-validate="Message is required"
//         >
//           <textarea className="input2" name="user_message"></textarea>
//           <span className="focus-input2" data-placeholder="MESSAGE"></span>
//         </div>
//         <div className="container-contact2-form-btn">
//           <button className="raise">
//             <a>Send Your Message &nbsp;</a>
//             <i className="fa-solid fa-arrow-right"></i>
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

const useStyles = makeStyles(() => ({
  textField: {
    "& label.Mui-focused": {
      color: "#94E458",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#BABBC4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#94E458",
    },
  },

  formComp: {
    "& .MuiFormControl-root": {
      marginBottom: 40,
      marginTop: 0,
    },
    "& .MuiInputBase-input": {
      marginTop: 2,
      // marginBottom: 1.5,
      fontFamily: "Poppins-Medium",
      color: "#94E458",
    },
    "& .MuiFormLabel-root": {
      color: "#BABBC4",
      fontFamily: "Poppins-Regular !important",
    },
    "& .MuiInputBase-colorSecondary": {
      color: "#94E458",
    },
  },
}));

// const StyledTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#94E458",
//   },
//   "& .MuiInput-underline:before": {
//     borderBottomColor: "#BABBC4",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#94E458",
//   },
// });

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const classes = useStyles();

  const setNameHandler = (nm) => {
    setName(nm);
  };

  const setEmailHandler = (em) => {
    setEmail(em);
  };

  const setMessageHandler = (mss) => {
    setMessage(mss);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rvwc2we",
        "template_6cino2m",
        form.current,
        process.env.REACT_APP_EMAIL_API_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formComp}
      ref={form}
      onSubmit={sendEmail}
    >
      {/* <form > */}{" "}
      <TextField
        id="standard-multiline-flexible"
        label="Name"
        name="user_name"
        maxRows={4}
        variant="standard"
        margin="dense"
        className={classes.textField}
        onChange={setNameHandler}
        fullWidth
      />
      <TextField
        id="standard-multiline-flexible"
        label="Email ID"
        name="user_email"
        maxRows={4}
        variant="standard"
        margin="dense"
        className={classes.textField}
        onChange={setEmailHandler}
        fullWidth
      />
      <TextField
        id="standard-multiline-static"
        label="Message"
        name="user_message"
        multiline
        rows={4}
        variant="standard"
        margin="dense"
        className={classes.textField}
        onChange={setMessageHandler}
        fullWidth
      />
      <div className="container-contact2-form-btn">
        <button className="raise" type="submit">
          <a>Send Your Message &nbsp;</a>
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      {/* </form> */}
    </Box>
  );
};

export default Form;
