import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { faChessBoard } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footerWrapper">
        <div className="section">
          <div className="brand">
            <h2>Ameya Dalvi</h2>
          </div>
          <div className="swe">Software Developer</div>
        </div>
        <div className="section">
          <div className="brand">
            <ul className="links">
              <li>
                <a href="#about">About Me</a>
              </li>
              <li>
                <a href="#qualification">Qualifications</a>
              </li>
              <li>
                <a href="#projects">Projects</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <div className="brand">
            <ul className="social">
              <li>
                <a
                  href="https://www.linkedin.com/in/ameyadalvi99/"
                  // className="fab fa-linkedin-in"
                >
                  {/* <FontAwesomeIcon icon="fa-brands fa-linkedin-in" /> */}
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/AmeyaDalvi"
                  // className="fab fa-github"
                >
                  <FaGithub />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ameya_dalvi_/"
                  // className="fab fa-instagram"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="https://friend.chess.com/eTqT">
                  <div
                    style={{
                      top: "-2px",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon icon={faChessBoard} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <p>
            Made with
            <span className="heart">&nbsp; &hearts; &nbsp;</span> using React JS
            and MUI
          </p>
          <br />
          <p>Icons from iconscout.com, fontawesome and devicon.dev</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
