import React, { forwardRef } from "react";
import Form from "./Form";
import FormContent from "./FormContent";
import { Section } from "./Section";

const Contact = forwardRef((props, ref) => {
  return (
    <div className="contactContainer" ref={ref} id="contact">
      <Section main={`Contact Me`} sub={`Connect with me!`} />
      <div className="formBody container">
        <div className="formStaticContent">
          <FormContent
            icon={"uil:phone-alt"}
            title={"Call Me"}
            sub={"+1(812)-671-5105"}
          />
          <FormContent
            icon={"uil:envelope-alt"}
            title={"Mail"}
            sub={"ameyadalvi1999@gmail.com"}
          />
          <FormContent
            icon={"uil:map-marker"}
            title={"Location"}
            sub={"Bloomington, IN"}
          />
        </div>
        <Form />
      </div>
    </div>
  );
});

export default Contact;
