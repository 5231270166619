import React from "react";
function NavbarTab({ className, tab, name, showSideBarHandler }) {
  return (
    <a
      className={className}
      href={`${tab}`}
      onClick={() => showSideBarHandler(false)}
    >
      {name}
    </a>
  );
}

export default NavbarTab;
