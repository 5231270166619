import React from "react";

function Blob() {
  return (
    <div className="blob">
      <img className="image" src="../images/Ameya-hero.png" alt="Hero Ameya" />
    </div>
  );
}

export default Blob;
