import React, { forwardRef } from "react";
import { Section } from "./Section";
const About = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="about">
      <Section main={`About Me`} sub={`My Introduction`} />
      <div className="container">
        <div className="about titleContainer ">
          <div className="image2">
            <img src="images/about-me-img-2.jpeg" alt="" />
          </div>
          <div className="image1">
            <img src="images/about-me-img.jpeg" alt="" />
          </div>
          <div className="aboutP">
            <div className="aboutPara">
              <p className="aboutText">
                Hello! I am a Software Developer with background in Web
                Development and Machine Learning. My inclination towards using
                modern technology to solve the challenging real world problems
                has led to me to pursue my master's in Computer Science at
                Indiana University Bloomington.
              </p>
              <p className="aboutText">
                In my recent role as a software engineer intern at Rocket
                Central, I have made a tangible impact by upgrading the front
                end using React and migrating products to AWS. As an associate
                software engineer at Xoriant Solutions, I provided maintenance
                support for Finastra's Global Pay Plus application, rectified
                the codebase in Java Spring Boot, and conducted knowledge
                transfer sessions for new team members.
              </p>
              <p className="aboutText">
                Whether in the classroom, on the job, or through personal
                projects, I am committed to professional growth and delivering
                high-quality software solutions. Outside of my professional
                life, I’m an avid chess and guitar player! Now let's dive deeper
                into my portfolio!
              </p>

              <div className="gitLink">
                <button className="raise">
                  <a href="assets/resume.pdf" download="resume.pdf">
                    Download CV
                  </a>
                </button>
                <a
                  href="https://www.linkedin.com/in/ameyadalvi99/"
                  className="fab fa-linkedin raise-icon"
                ></a>
                <a
                  href="https://github.com/AmeyaDalvi"
                  className="fab fa-github raise-icon"
                  aria-hidden={true}
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

/* Hello! I am a Software Developer with background in Web Development and Machine Learning. My inclination towards using modern technology to solve the challenging real world problems has led to me to pursue my master's in Computer Science at Indiana University Bloomington.

I was able to use my technical expertise throughout my time as a Software Engineer at Xoriant and Summer Intern at Rocket to create new front-ends, upgrade pre-existing ones, and work on back-end technologies that utilised Java and C#. I also learned about creating deployment pipelines, writing Infrastructure as a Code, and deploying cloud-formation stacks to AWS. Going further, I would love to gain more exposure to Cloud Technologies and System Desigining

Outside of my professional life, I’m an avid chess and guitar player! Now let's dive deeper into my portfolio!
*/
export default About;
