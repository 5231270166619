import React, { forwardRef } from "react";
import { Section } from "./Section";
import Project from "./Project";
import { Grid } from "@mui/material";
import { projects } from "./ProjectsData";

const Projects = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="projects">
      <Section main={`Projects`} sub={`Some of my recent work`} />
      <div className="container projectContainer">
        <Grid container spacing={10} px={{ xs: 5, sm: 10, md: 5 }}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} key={project.alt}>
              <Project
                image={project.image}
                alt={project.alt}
                title={project.title}
                desc={project.desc}
                button1={project.button1}
                button2={project.button2}
                link={project.link}
                key={project.image}
                videoid={project.videoid}
                summary={project.summary}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
});

export default Projects;
