import React, { forwardRef } from "react";
import Blob from "./Blob";
import GreenButton from "./GreenButton";
import Typing from "./Typing";

const Home = forwardRef((props, ref) => {
  const strings = ["Developer", "Guitarist", "Chess Player"];
  return (
    <div className="home container" ref={ref} id="home">
      <div className="home__left">
        <div className="name">
          <h1>I'm Ameya Dalvi</h1>
        </div>
        <Typing strings={strings} />
        <div></div>
        <GreenButton title="See my work" />
      </div>
      <div className="home__right">
        <Blob />
      </div>
    </div>
  );
});

export default Home;
