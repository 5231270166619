import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const WorkSection = () => {
  const matches = useMediaQuery("(max-width:775px)");
  return (
    <div className="container timelineConatiner">
      <Timeline
        position={matches ? "right" : "alternate"}
        sx={
          matches
            ? {
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }
            : {}
        }
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
            <TimelineConnector
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion
              sx={{
                minWidth: 275,
                background: "#202020",
                color: "#fff",
                borderRadius: "15px",
              }}
              square
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#babbc4",
                      ":hover": { color: "greenyellow" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography
                    sx={{
                      background: "linear-gradient(to right, #c5e35b, #45b649)",
                      backgroundClip: "text",
                      backgroundSize: "100%",
                      textDecoration: "none",
                      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                      fontSize: "1.25rem",
                      fontWeight: "800",
                    }}
                    align="left"
                  >
                    Software Engineer Intern
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    color={"#babbc4"}
                    align="left"
                  >
                    Rocket Central
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="bullets">
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Upgraded the front end for Rocket's internal websites
                        using React, enhancing team members' user experience
                        with an approval rate of 83%
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Migrated company products to AWS by creating
                        CloudFormation stacks using Terraform and Serverless
                        Framework.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Leveraged Terraform to write IaC (Infrastructure as
                        Code) to deploy AuroraDB to AWS.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
            <TimelineConnector
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion
              sx={{
                minWidth: 275,
                background: "#202020",
                color: "#fff",
                borderRadius: "15px",
              }}
              square
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#babbc4",
                      ":hover": { color: "greenyellow" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography
                    sx={{
                      background: "linear-gradient(to right, #c5e35b, #45b649)",
                      backgroundClip: "text",
                      backgroundSize: "100%",
                      textDecoration: "none",
                      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                      fontSize: "1.25rem",
                      fontWeight: "800",
                    }}
                    align="left"
                  >
                    Head Associate Instructor
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    color={"#babbc4"}
                    align="left"
                  >
                    Indiana University Bloomington
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="bullets">
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Mentored 30+ students on Machine Learning concepts by
                        conducting doubt-solving lectures and code review
                        session.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Built a Docker container of machine learning libraries &
                        frameworks to ensure a consistent working environment.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Evaluated and contributed new content to lessons by
                        creating assignments, exam solutions and course content.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion
              sx={{
                minWidth: 275,
                background: "#202020",
                color: "#fff",
                borderRadius: "15px",
              }}
              square
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#babbc4",
                      ":hover": { color: "greenyellow" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography
                    sx={{
                      background: "linear-gradient(to right, #c5e35b, #45b649)",
                      backgroundClip: "text",
                      backgroundSize: "100%",
                      textDecoration: "none",
                      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                      fontSize: "1.25rem",
                      fontWeight: "800",
                    }}
                    align="left"
                  >
                    Consultant from Xoriant
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    color={"#babbc4"}
                    align="left"
                  >
                    Finastra/Xoriant
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="bullets">
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Resolved highest number of high-priority deliverables in
                        a sprint, contributing towards most valuable business
                        KPIs.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Revamped legacy code in Java SpringBoot to solve
                        multiple defects at once; reduced man-hours required for
                        micro-management of those defects by 20%.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1rem", fontWeight: "500" }}
                        color={"#babbc4"}
                        align="left"
                      >
                        Conducted presentations and sessions for new joiners on
                        Mass Payment flow and its defect resolution strategies.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
};

export default WorkSection;
