import React from "react";
import NavbarTab from "./NavbarTab";
import { FaTimes } from "react-icons/fa";

function SideBar({ activeTab, showSideBarHandler }) {
  return (
    <div className="sidebar">
      <div className="close-side-nav" onClick={() => showSideBarHandler(false)}>
        <FaTimes />
      </div>

      <NavbarTab
        tab="#home"
        name="Home"
        className={activeTab() === 0 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
      <NavbarTab
        tab="#about"
        name="About"
        className={activeTab() === 1 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
      <NavbarTab
        tab="#qualification"
        name="Qualification"
        className={activeTab() === 2 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
      <NavbarTab
        tab="#projects"
        name="Projects"
        className={activeTab() === 3 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
      <NavbarTab
        tab="#skills"
        name="Skills"
        className={activeTab() === 4 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
      <NavbarTab
        tab="#contact"
        name="Contact"
        className={activeTab() === 5 ? "activeTab navbarTab" : "navbarTab"}
        showSideBarHandler={showSideBarHandler}
      />
    </div>
  );
}

export default SideBar;
