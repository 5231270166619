import React, { useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import YouTube from "react-youtube";
import CloseIcon from "@mui/icons-material/Close";

const Project = ({
  image,
  alt,
  title,
  desc,
  button1,
  button2,
  link,
  videoid,
  summary,
}) => {
  const [openModal, setModalOpen] = useState(false);

  const modalOpenHandler = () => {
    setModalOpen(true);
  };
  const modalClosehandler = () => {
    setModalOpen(false);
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Box>
      <Card
        sx={{ maxWidth: 500, backgroundColor: "#202020", borderRadius: "10px" }}
      >
        <CardActionArea onClick={() => modalOpenHandler()}>
          <CardMedia
            component="img"
            //   height="300"
            image={image}
            alt={alt}
            className="projectImg"
          />
        </CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              background: "linear-gradient(to right, #c5e35b, #45b649)",
              backgroundClip: "text",
              backgroundSize: "100%",
              textDecoration: "none",
              WebkitTextFillColor: "rgba(0, 0, 0, 0)",
              fontSize: "1.5rem",
              fontWeight: "800",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color={"#babbc4"}>
            {desc}
          </Typography>
        </CardContent>
        <CardActions>
          {button1 !== "null" && (
            <Button
              sx={{
                background: "none",
                font: "inherit",
                lineHeight: "1",
                padding: "0.5em 1em",
                border: "2px solid rgb(108, 156, 35)",
                transition: "0.25s",
                borderRadius: "10px",
                color: "rgb(108, 156, 35)",
                fontSize: "14px",
                margin: "10px",
                ":hover": { color: "greenyellow" },
                ":focus": { border: "2px solid rgb(108, 156, 35)" },
              }}
              onClick={() => modalOpenHandler()}
            >
              {button1}
            </Button>
          )}
          {button2 !== "null" && (
            <Button
              sx={{
                background: "none",
                font: "inherit",
                lineHeight: "1",
                padding: "0.5em 1em",
                border: "2px solid rgb(108, 156, 35)",
                transition: "0.25s",
                borderRadius: "10px",
                color: "rgb(108, 156, 35)",
                fontSize: "14px",
                margin: "10px",
                ":hover": { color: "greenyellow" },
                ":focus": { border: "2px solid rgb(108, 156, 35)" },
              }}
            >
              <a
                className="projectLink"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                {button2}
              </a>
            </Button>
          )}
        </CardActions>
      </Card>
      <Dialog
        open={openModal}
        onClose={modalClosehandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogContent>
          <Box className={"titleClose"}>
            <Typography
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
                backgroundClip: "text",
                backgroundSize: "100%",
                textDecoration: "none",
                WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                fontSize: "23px",
                fontWeight: "800",
              }}
            >
              {title}
            </Typography>
            <button onClick={modalClosehandler} className={"closeButton"}>
              <CloseIcon className={"closeIcon"} />
            </button>
          </Box>
          <Box>
            <YouTube
              className={"youtube"}
              videoId={videoid}
              opts={opts}
              onReady={onPlayerReady}
            />
          </Box>
          <Box className={"summaryWrapper"}>
            <Typography fontSize="20px" fontWeight={"bold"} color="#babbc4">
              Summary
            </Typography>
            <Typography fontSize="16px" fontWeight={"medium"} color="#babbc4">
              {summary}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Project;
