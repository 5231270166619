import React from "react";

export const Section = (props) => {
  return (
    <div className="container">
      <div className="sectionHead">
        <h2>{props.main}</h2>
        <p>{props.sub}</p>
      </div>
    </div>
  );
};
