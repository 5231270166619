import React from "react";
import { Icon } from "@iconify/react";

const FormContent = ({ icon, title, sub }) => {
  return (
    <div>
      <div className="contactInfo">
        <Icon icon={icon} className="contactIcon" />
        <div className="contactTitleSub">
          <h3 className="contactTitle">{title}</h3>
          <h4 className="contactSubtitle">{sub}</h4>
        </div>
      </div>
    </div>
  );
};

export default FormContent;
