export const skills = [
  {
    name: "HTML5",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain.svg",
    style: { color: "#E44D26" },
  },
  {
    name: "CSS3",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg",
    style: { color: "#1572B6" },
  },
  {
    name: "JavaScript",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg",
    style: { color: "#F0DB50" },
  },
  {
    name: "ReactJS",
    src: "images/languages3.png",
    style: { color: "#00D8FF" },
  },
  {
    name: "Bootstrap",
    src: "images/bootstrap.png",
    style: { color: "#7952B3" },
  },
  {
    name: "Sass",
    src: "images/languages5.png",
    style: { color: "#CF649A" },
  },
  {
    name: "Figma",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
    style: {
      background:
        "linear-gradient(to right,#F24E1E,#FF7262,#A259FF,#1ABCFE,#0ACF83)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
    },
  },
  {
    name: "Flask",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg",
    style: { color: "#fff" },
  },
  {
    name: "Python",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-plain.svg",
    style: { color: "#FFD844" },
  },
  {
    name: "Java",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-plain.svg",
    style: { color: "#EA2D2E" },
  },
  {
    name: "MySQL",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
    style: { color: "#00618A" },
  },
  {
    name: "MongoDB",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg",
    style: { color: "#50AA41" },
  },
  {
    name: "PostgreSQL",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-plain.svg",
    style: { color: "#336791" },
  },
  {
    name: "Docker",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg",
    style: { color: "#4499c2" },
  },
  {
    name: "Firebase",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
    style: { color: "#e6883c" },
  },
  {
    name: "Heroku",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg",
    style: { color: "#6762A6" },
  },
  {
    name: "Netlify",
    src: "images/netlify.png",
    style: { color: "#438CA7" },
  },
  {
    name: "Git",
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
    style: { color: "#e15b39" },
  },
];
