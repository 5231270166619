import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";

const EducationSection = () => {
  const matches = useMediaQuery("(max-width:775px)");
  return (
    <div className="container timelineConatiner">
      <Timeline
        position={matches ? "right" : "alternate"}
        sx={
          matches
            ? {
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }
            : {}
        }
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
            <TimelineConnector
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion
              sx={{
                minWidth: 275,
                background: "#202020",
                color: "#fff",
                borderRadius: "10px",
              }}
              square
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#babbc4",
                      ":hover": { color: "greenyellow" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography
                    sx={{
                      background: "linear-gradient(to right, #c5e35b, #45b649)",
                      backgroundClip: "text",
                      backgroundSize: "100%",
                      textDecoration: "none",
                      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                      fontSize: "1.25rem",
                      fontWeight: "800",
                    }}
                    align="left"
                  >
                    Computer Science
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    color={"#babbc4"}
                    align="left"
                  >
                    Masters of Science at Indiana University
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="bullets">
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "500" }}
                    variant="body2"
                    color={"#babbc4"}
                    align="left"
                  >
                    <ul>
                      <li> GPA: 3.7/4</li>
                      <li>
                        Courses: Elements of Artificial Intelligence, Applied
                        Algorithms, Applied Machine Learning, Applied Database
                        Technologies, Advanced Distributed Systems, Cross
                        Platform Mobile Computing(React Native).
                      </li>
                    </ul>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                background: "linear-gradient(to right, #c5e35b, #45b649)",
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion
              sx={{
                minWidth: 275,
                background: "#202020",
                color: "#fff",
                borderRadius: "15px",
              }}
              square
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#babbc4",
                      ":hover": { color: "greenyellow" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography
                    sx={{
                      background: "linear-gradient(to right, #c5e35b, #45b649)",
                      backgroundClip: "text",
                      backgroundSize: "100%",
                      textDecoration: "none",
                      WebkitTextFillColor: "rgba(0, 0, 0, 0)",
                      fontSize: "1.25rem",
                      fontWeight: "800",
                    }}
                    align="left"
                  >
                    Computer Engineering
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    color={"#babbc4"}
                    align="left"
                  >
                    Bachelor of Engineering at University of Mumbai
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="bullets">
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "500" }}
                    variant="body2"
                    color={"#babbc4"}
                    align="left"
                  >
                    <ul>
                      <li>GPA: 3.8/4</li>
                      <li>
                        Courses: Web Development,Database Management Systems,
                        Distributed Systems, Cloud Computing, Advanced
                        Algorithms, Machine Learning, Artificial Intelligence
                      </li>
                    </ul>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
};

export default EducationSection;
