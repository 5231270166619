import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaAngleUp } from "react-icons/fa";

const ScrollToTop = () => {
  useEffect(() => {
    window.addEventListener("scroll", scroll);
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  });
  const scroll = (e) => {
    const scrollto = document.querySelector(".scrollToTop");
    const scrollTop = window.scrollY;
    scrollTop >= 350
      ? scrollto.classList.add("scrollShow")
      : scrollto.classList.remove("scrollShow");
  };

  return (
    <div className="scrollToTop">
      <a href="#home" className="fas fa-angle-up"></a>
    </div>
  );
};

export default ScrollToTop;
