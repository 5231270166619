import { Button } from "@mui/material";
import React, { forwardRef, useState } from "react";
import WorkSection from "./WorkSection";
import { Section } from "./Section";
import EducationSection from "./EducationSection";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import SchoolIcon from "@mui/icons-material/School";

const Qualifications = forwardRef((props, ref) => {
  const [education, setEducation] = useState(false);
  const [workColor, setWorkColor] = useState("greenyellow");
  const [educolor, setEduColor] = useState("#babbc4");

  const showEducationHandler = () => {
    setEducation(true);
    setEduColorHandler();
    unsetWorkColorHandler();
  };
  const unshowEducationHandler = () => {
    setEducation(false);
    setWorkColorHandler();
    unsetEduColorHandler();
  };

  const setWorkColorHandler = () => {
    setWorkColor("greenyellow");
  };

  const unsetWorkColorHandler = () => {
    setWorkColor("#babbc4");
  };

  const setEduColorHandler = () => {
    setEduColor("greenyellow");
  };

  const unsetEduColorHandler = () => {
    setEduColor("#babbc4");
  };

  return (
    <div className="qualificationWrapper" ref={ref} id="qualification">
      <Section
        main={`Qualifications`}
        sub={`Educational details and Industry Experience`}
      />
      <div className="container qualificationContainer">
        <Button
          sx={{
            background: "none",
            font: "inherit",
            lineHeight: "1",
            padding: "0.5em 1em",
            transition: "0.25s",
            borderRadius: "10px",
            color: workColor,
            fontSize: "18px",
            fontWeight: "bold",
            margin: "10px",
            ":hover": { color: "greenyellow", background: "none" },
            ":focus": { color: "greenyellow" },
          }}
          onClick={() => {
            unshowEducationHandler();
          }}
        >
          <WorkOutlineIcon
            sx={{
              transition: "0.25s",
              color: workColor,
              ":hover": { color: "greenyellow", background: "none" },
            }}
          />
          &nbsp; Work
        </Button>
        <Button
          sx={{
            background: "none",
            font: "inherit",
            lineHeight: "1",
            padding: "0.5em 1em",
            transition: "0.25s",
            borderRadius: "10px",
            color: educolor,
            fontSize: "18px",
            fontWeight: "bold",
            margin: "10px",
            ":hover": { color: "greenyellow", background: "none" },
            ":focus": { color: "greenyellow" },
          }}
          onClick={() => showEducationHandler()}
        >
          <SchoolIcon
            sx={{
              transition: "0.25s",
              color: educolor,
              ":hover": { color: "greenyellow", background: "none" },
            }}
          />
          &nbsp; Education
        </Button>
      </div>
      {education ? <EducationSection /> : <WorkSection />}
    </div>
  );
});

export default Qualifications;
