export const projects = [
  {
    image: require("../project-images/RIO1.png"),
    alt: "RIO",
    title: "Rent-It-Out",
    desc: "An online user-friendly platform for managing short-term rentals in multiple categories, making renting products easy and hassle-free.",
    button1: "Demo",
    button2: "Website",
    link: "https://rio-delta.vercel.app/",
    videoid: "qVdEEspFziY",
    summary:
      "Rent-It-Out (RIO) is a platform for renting out products and finding rentals. Users can post items for rent, set their own prices, and manage their inventory. Renters can easily search for products and contact owners to arrange rental details. RIO provides a hassle-free way to make extra income and find items for short-term use.",
  },
  {
    image: require("../project-images/Readify2.png"),
    alt: "Readify",
    title: "Readify",
    desc: "An accessible online book suggestion service for discovering new books, building booklists, and broadening your reading interests.",
    button1: "Demo",
    button2: "Website",
    link: "https://readify-app.herokuapp.com/",
    videoid: "vidfyJ5aVrk",
    summary:
      "Readify is a mobile-friendly book recommendation platform that offers engaging book recommendations. We provide detailed information and opportunities to learn more about over 50,000 best-selling books. Users can browse books, apply filters based on author, title, genre, and rating, as well as create booklists to save interesting books.",
  },
  {
    image: require("../project-images/Shorts.png"),
    alt: "Shorts",
    title: "Shorts",
    desc: "A cross-platform mobile news application with voice-enabled search functionality to fetch latest news articles based on user's voice input.",
    button1: "Demo",
    button2: "Github",
    link: "https://github.com/AmeyaDalvi/Shorts",
    videoid: "g0DHppQ9Vkc",
    summary:
      "Shorts is a cross-platform mobile news application that can fetch news articles based on user's text or voice input. Users of the app would be able to look for news stories using a variety of categories, search phrases, and news sources.",
  },
];
