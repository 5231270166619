import React from "react";

function GreenButton({ title }) {
  return (
    <div>
      <a className="raise aButton" href="#projects" rel="noreferrer">
        <span>{title}</span>
      </a>
    </div>
  );
}

export default GreenButton;
