import React from "react";

const Lower = () => {
  return (
    <div className="lower">
      <span>&copy; Ameya Dalvi. 2022 all rights reserved.</span>
    </div>
  );
};

export default Lower;
