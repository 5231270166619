import React, { useEffect } from "react";
import NavbarTab from "./NavbarTab";
import { FaBars } from "react-icons/fa";

function Navbar({ activeTab, showSideBarHandler }) {
  // const handleClick = () => setClick((prev) => !prev);
  const dummy = ({ state }) => {};
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 0
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <div className="header">
      <div className="navbar container">
        <div className="navbar__left">
          <div className="navbar__logo">
            <a href="#home">AD</a>
          </div>
        </div>

        <div className="burger" onClick={() => showSideBarHandler(true)}>
          <FaBars />
        </div>

        <div className="navbar__right">
          <NavbarTab
            tab="#home"
            name="Home"
            className={activeTab() === 0 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
          <NavbarTab
            tab="#about"
            name="About"
            className={activeTab() === 1 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
          <NavbarTab
            tab="#qualification"
            name="Qualification"
            className={activeTab() === 2 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
          <NavbarTab
            tab="#projects"
            name="Projects"
            className={activeTab() === 3 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
          <NavbarTab
            tab="#skills"
            name="Skills"
            className={activeTab() === 4 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
          <NavbarTab
            tab="#contact"
            name="Contact"
            className={activeTab() === 5 ? "activeTab navbarTab" : "navbarTab"}
            showSideBarHandler={dummy}
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
